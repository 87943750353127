import React from "react"
import { Box3, Content } from "../../components/UI"
import { LandingScreen } from "../Home/Styled"
import "./Careers.css"
import home_mobile from "../../images/home_mobile.jpg"
import Signup from "../Home/signup"

function Homepage() {
  return (
    <div>
      <div className="mobile_text">
        <h1>
          We are <span style={{ color: "rgb(0, 191, 255)" }}>hiring!</span>
        </h1>
        {/* <span style={{ color: "rgb(0, 191, 255)" }}> BEST</span> out of your{" "}
          <span style={{ color: "rgb(0, 191, 255)" }}> SALES & OPERATIONS</span>{" "} */}
      </div>
      <LandingScreen mobileBackgroundImage={`url(${home_mobile})`}>
        <div className="landing_image_main_box">
          <div className="landing_image_main_box_first_child">
            <div className="image_text_For_landing_page">We are hiring!</div>
          </div>
        </div>
      </LandingScreen>

      <div className="page_body1">
        <div className="career-heading">
          <Content></Content>
        </div>
        {/* <Career> */}
        <Box3>
          <Content>
            <div className="sub-heading" style={{ color: "#333" }}>
              We are a young dynamic team and we are growing.
              <br />
              <br />
              Looking for colleagues with experience in Front-end technology
              like Reactjs, redux, React-native
            </div>
            <br />
            <h1>
              Are you interested?
              <br />
            </h1>
            <h2 style={{ color: "black", fontWeight: "normal" }}>
              Send your Resume to support@tekorero.com
            </h2>
          </Content>
        </Box3>
        {/* </Career> */}
      </div>
      <div className="footer-career" style={{ marginTop: "150px" }}>
        <Content></Content>
      </div>
      <div className="container-3">
        <Signup />
      </div>
      {/* <div className="footer"></div> */}
    </div>
  )
}

export default Homepage

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Text4 from "../modules/Careers/Careers"

const Careers = () => (
  <Layout>
    <SEO title="Careers" />
    <Text4 />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default Careers
